import './AboutMeSection.scss';
import { FormattedMessage } from 'react-intl';
import { sectionId } from './IntroSection';
import { LegacyRef, useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

type Props = {
    refs: LegacyRef<HTMLElement> | undefined;
};

export const AboutMeSection = ({ refs }: Props) => {
    const { theme, dark } = useContext(ThemeContext);

    return (
        <section
            data-section
            ref={refs}
            className="aboutme-section__container"
            style={dark ? theme : {}}
            id={sectionId.ABOUT_ID}
        >
            <h1 className="aboutme-section__heading">
                <FormattedMessage id="ABOUT_HEADING" />
            </h1>
            <p className="aboutme-section__content" style={dark ? theme : {}}>
                <FormattedMessage
                    id="ABOUTME_TEXT"
                    values={{
                        br: () => <br />,
                    }}
                />
            </p>
        </section>
    );
}
