import { ReactNode, createContext, useEffect, useState } from 'react';
import { appThemes } from '../appThemes';

export type Theme = 'light' | 'dark' | null;

type Props = {
  children: ReactNode;
};

type ThemeContextProps = {
  theme: React.CSSProperties;
  toggleTheme: () => void;
  dark: boolean | null;
};

const initialState: ThemeContextProps = {
  dark: false,
  theme: appThemes.light,
  toggleTheme: () => {},
};

const ThemeContext = createContext<ThemeContextProps>(initialState);

function ThemeProvider({ children }: Props) {
  const [dark, setDark] = useState<boolean | null>(null);

  useEffect(() => {
    const isDark = localStorage.getItem('dark') === 'true';
    setDark(isDark);
  }, [dark]);

  const toggleTheme = () => {
    const isDark = !dark;
    localStorage.setItem('dark', JSON.stringify(isDark));
    setDark(isDark);
  };

  const theme = dark ? appThemes.dark : appThemes.light;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, dark }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
