export type AppTheme = {
  dark: React.CSSProperties;
  light: React.CSSProperties;
};

export const appThemes: AppTheme = {
  dark: {
    backgroundColor: 'black',
    color: 'white',
    accentColor: 'white'
  },
  light: {
    backgroundColor: 'white',
    color: 'black',
    accentColor: 'black'
  },
};
