import './App.scss';
import { ContactSection } from './components/ContactSection';
import { Menu } from './components/Menu';
import { IntroSection } from './components/IntroSection';
import { AboutMeSection } from './components/AboutMeSection';
import { HobbySection } from './components/HobbySection';
import { useContext, useRef } from 'react';
import { ThemeContext, ThemeProvider } from './context/ThemeContext';

function App() {
  const sectionsRef = useRef<any>([]);
  const { dark, theme } = useContext(ThemeContext)

  return (
    <ThemeProvider>
      <div id="app" className="app" style={dark ? theme : {}}>
        <Menu refs={sectionsRef} />
        <div className="main-content">
          <div className="main-content__scrollable" >
            <IntroSection refs={(el: any) => sectionsRef.current.push(el as any)} />
            <AboutMeSection refs={(el: any) => sectionsRef.current.push(el as any)} />
            <HobbySection refs={(el: any) => sectionsRef.current.push(el as any)} />
          </div>
          <ContactSection />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
