export const messages = {
  GREETING: `
  <greeting>Hey there! I'm</greeting>
  <author>Mayumi,</author>
  <br></br>
  <br></br>
  <pronunciation>pronounced "my-you-me"</pronunciation>
  `,

  INTRODUCTION: `I am a full stack software engineer who loves front-end development.

  Currently working as a
  <strong>Senior Software Engineer</strong>
  at Xero.
  And based in Auckland, New Zealand.
  `,

  ABOUTME_TEXT: `
  I have extensive enterprise exposure to modern software development practices, tools and philosophy across various software development projects in Australia, the U.S., Brazil, and India.
  <br></br>
  <br></br>
  My skills include fluency in JavaScript/TypeScript, React/React Native, and Node.JS. Additionally, I have rich experience in Unit, Integration and E2E Testings.
  <br></br>
  <br></br>
  I am conversant with DevOps and take responsibility for shipping code and monitoring the health of the applications as well as their infrastructure.
  <br></br>
  <br></br>
  I have cross-functional experience in SDLC with an agile framework.
  <br></br>
  <br></br>
  I love to learn, share knowledge, and blog about my experiences.
  `,

  HOBBIES_TEXT: `
  My hobbies are pretty varied.
  <br></br>
  <br></br>
  I love reading books so much that I'm actually working on a React Native app to keep track of all the ones I've read.
  <br></br>
  <br></br>
  I'm also really into solving coding problems on <profile>Codewars</profile> - I'm even raking at <badge>4 kuy</badge>! Sometime, I can get so lost in it that I forget what time it is.
  <br></br>
  <br></br>
  When I'm not coding, I like to cook, travel, exercise and spend time with my partner and our dog, Summer.
  <br></br>
  <br></br>
  I'm also a huge wine fan and dream of having my own wine cellar one day.`,
  FOOTER_TEXT: `This site was built with<span></span>using Typescript and React.`,
  SAY_HI: 'Say hi',
  LINKEDIN: 'LinkedIn',
  MEDIUM: 'Medium',
  GITHUB: 'GitHub',
  LINKEDIN_LINK: 'https://www.linkedin.com/in/raquel-mayumi-nishimoto-4877028',
  MEDIUM_LINK: 'https://medium.com/@mayuminishimoto',
  GITHUB_LINK: 'https://github.com/raquelnishimoto',
  CODEWARS_PROFILE: 'https://www.codewars.com/users/raquelnishimoto/completed_solutions',
  CODEWARS_BADGE: 'https://www.codewars.com/users/raquelnishimoto/badges/large',
  EMAIL: 'sayhellomayumi@gmail.com',
  INTRO: 'Intro',
  ABOUT: 'About',
  ABOUT_HEADING: 'About me',
  HOBBIES: 'Hobbies',
  HOBBIES_HEADING: 'Hobbies',
  VIEW_RESUME: 'View resume'
};
