import './HobbySection.scss';
import { messages } from '../en-NZ';
import { FormattedMessage } from 'react-intl';
import { sectionId } from './IntroSection';
import { LegacyRef, useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

type Props = {
    refs: LegacyRef<HTMLElement> | undefined;
};

export const HobbySection = ({ refs }: Props) => {
    const { CODEWARS_BADGE, CODEWARS_PROFILE } = messages;
    const { theme, dark } = useContext(ThemeContext);

    return (
        <section
            data-section
            ref={refs}
            className="hobby-section__container"
            style={dark ? theme : {}}
            id={sectionId.HOBBIES_ID}
        >
            <h1 className="hobby-section__heading">
                <FormattedMessage id="HOBBIES_HEADING" />
            </h1>
            <p className="hobby-section__content">
                <FormattedMessage
                    id="HOBBIES_TEXT"
                    values={{
                        br: () => <br />,
                        profile: (value) => (
                            <a
                                style={dark ? theme : {}}
                                href={CODEWARS_PROFILE}
                                target="_blank"
                                rel="noreferrer"
                                referrerPolicy="no-referrer"
                            >
                                {value}
                            </a>
                        ),
                        badge: (value) => (
                            <a
                                style={dark ? theme : {}}
                                href={CODEWARS_BADGE}
                                target="_blank"
                                rel="noreferrer"
                                referrerPolicy="no-referrer"
                            >
                                {value}
                            </a>
                        ),
                        h2: (text) => <h2>{text}</h2>,
                    }}
                />
            </p>
        </section>
    );
}
