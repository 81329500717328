import './IntroSection.scss';
import { LegacyRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Resume from '../assets/documents/resume.pdf';
import { ThemeContext } from '../context/ThemeContext';

type Props = {
  refs: LegacyRef<HTMLElement> | undefined;
};

export const sectionId = {
  ABOUT_ID: 'aboutme',
  HOBBIES_ID: 'hobbies',
  INTRO_ID: 'intro',
}

export const IntroSection = ({ refs }: Props) => {
  const { theme, dark } = useContext(ThemeContext);

  return (
    <section
      ref={refs}
      data-section
      className="intro-section__container"
      style={dark ? theme : {}}
      id={sectionId.INTRO_ID}
    >
      <h1 className="intro-section__greeting">
        <FormattedMessage id="GREETING" values={{
          br: () => <br />,
          greeting: (value) => {
            return (
              <div className="intro-section__greeting">
                {value}
              </div>)
          },
          author: (value) => {
            return (
              <div className="intro-section__greeting--large-bold">
                {value}
              </div>)
          },
          pronunciation: (value) => {
            return (
              <div className="intro-section__greeting--small-italic">
                {value}
              </div>
            );
          }
        }} />
      </h1>
      <p className="intro-section__intro-text">
        <FormattedMessage
          id="INTRODUCTION"
          values={{
            br: () => <br />,
            strong: (value) => <strong>{value}</strong>,
            h2: (text) => <h2>{text}</h2>,
          }}
        />
      </p>
      <a
        style={dark ? theme : {}}
        className='intro-section__link'
        href={Resume}
        target='_blank'
        rel="noreferrer"
        aria-label='open resume'
      >
        <FormattedMessage id='VIEW_RESUME' />
        <OpenInNewIcon className='intro-section__link-icon' fontSize='small' />
        {/* <img
          className='intro-section__link-icon'
          aria-label='external link'
          src={ExternalLink}
          height='16'
          width='16'
        /> */}
      </a>
    </section>
  );
}
