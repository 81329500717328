import './ContactSection.scss';

import { messages } from '../en-NZ';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

export const ContactSection = () => {
  const { theme, dark } = useContext(ThemeContext);

  const {
    LINKEDIN,
    MEDIUM,
    GITHUB,
    LINKEDIN_LINK,
    MEDIUM_LINK,
    GITHUB_LINK,
  } = messages;
  const constactLinks = {
    [GITHUB]: GITHUB_LINK,
    [MEDIUM]: MEDIUM_LINK,
    [LINKEDIN]: LINKEDIN_LINK,
  };

  return (
    <footer className="contact-content" style={dark ? theme : {}}>
      <span className="contact-content__heading">
        <h3>
          <FormattedMessage id="SAY_HI" />
        </h3>
        <WavingHandIcon fontSize="small" className='icon' />
      </span>
      <ul className="contact-content__link-list no-bullet">
        {Object.keys(constactLinks).map((link) => {
          return (
            <li className="contact-content__link" key={link}>
              <a
                style={dark ? theme : {}}
                href={constactLinks[link]}
                referrerPolicy="no-referrer"
                target="_blank"
                rel="noreferrer"
              >
                {link}
              </a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
};
