import "./Menu.scss";
import { messages } from "../en-NZ"
import { sectionId } from "./IntroSection";
import { BaseSyntheticEvent, MutableRefObject, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { ThemeRadioGroup } from "./ThemeGroup";

type SelectedItem = 'intro' | 'about' | 'hobbies';
type Props = {
    refs: MutableRefObject<[] | [HTMLElement | null]>;
};
const { INTRO, ABOUT, HOBBIES } = messages;

const menuItems = [
    { name: INTRO, id: sectionId.INTRO_ID },
    { name: ABOUT, id: sectionId.ABOUT_ID },
    { name: HOBBIES, id: sectionId.HOBBIES_ID },
];

const getNameOfSection = (section: IntersectionObserverEntry) => {
    return menuItems.find(item => item.id === section.target.id)?.name
};

export const Menu = ({ refs }: Props) => {
    const [selectedItem, setSelectedItem] = useState<SelectedItem>('intro');
    const { toggleTheme, dark, theme } = useContext(ThemeContext);

    useEffect(() => {
        const sections = refs?.current;

        let options = {
            rootMargin: "0px",
            threshold: 0.7,
        };

        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const sectionInFocusName = getNameOfSection(entry);
                    setSelectedItem(sectionInFocusName as SelectedItem);
                }
            })
        }, options);


        sections.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            sections.forEach(section => {
                if (section) {
                    return observer.unobserve(section)
                };
            });
        };
    }, [refs]);

    const onClick = (e: BaseSyntheticEvent<MouseEvent>) => {
        setSelectedItem(e.target.innerHTML);
    };

    return (
        <nav className="menu" style={dark ? theme : {}} role="menu">
            <ul className="menu__list" style={dark ? theme : {}}>
                {menuItems.map(({ name, id }) => {
                    return (
                        <li key={`id_${id}`}>
                            <a
                                className={`menu__item ${selectedItem.toLowerCase() === name.toLowerCase() ? 'menu__item-selected' : ''}`}
                                style={dark ? theme : {}}
                                key={id}
                                href={`#${id}`}
                                onClick={onClick}
                            >
                                {name}
                            </a>
                        </li>
                    );
                })}</ul>
        </nav>)
}
